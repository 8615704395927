<template>
    <div class="carrot-layer-bg">
        <div class="carrot-layer w-600px">
            <div>
                <p class="title">강의장 예약 가능 검색</p> 
                <span class="btn-close" @click="pop.onClose()"><i class="fas fa-times"></i></span>
            </div>
            <div class="mt-10 tbl-light overhidden overscroll maxh-600px">

                <table class="tbl-light-view">
                    <colgroup>
                        <col style="width:70px">
                        <col style="width:auto">
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>기간</th>
                            <td>
                                <label><input type="radio" v-model="pop.dtype" @change="comp.changeDaytype" value="oneday"><span class="ml-5 mr-5">하루</span></label>
                                <div class="po-relative dp-inblock h-32px">
                                    <div v-if="pop.dtype=='period'" class="input-lock"></div>
                                    <CarrotDatePicker v-model.sync="pop.tdate" @change="comp.changeDate" class="w-110px dp-inblock"></CarrotDatePicker>
                                </div>
                                <label class="ml-15"><input type="radio" v-model="pop.dtype" @change="comp.changeDaytype" value="period"><span class="ml-5 mr-5">기간</span></label>
                                <div class="po-relative h-32px dp-inblock">
                                    <div v-if="pop.dtype=='oneday'" class="input-lock"></div>
                                    <CarrotDatePicker v-model.sync="pop.sdate" class="w-110px dp-inblock"></CarrotDatePicker>
                                    ~ 
                                    <CarrotDatePicker v-model.sync="pop.edate" class="w-110px dp-inblock"></CarrotDatePicker>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>요일</th>
                            <td>
                                <div class="po-relative dp-inblock w-400px h-32px">
                                    <div v-if="pop.dtype=='oneday'" class="input-lock"></div>
                                    <label v-for="d in comp.dayList" :key="d"><input type="checkbox" v-model="pop.days" :value="d"><span class="ml-5 mr-20"> {{ d }}</span></label>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th>시간</th>
                            <td>
                                <CarrotTime24Select v-model="pop.stime"></CarrotTime24Select>
                                ~ 
                                <CarrotTime24Select v-model="pop.etime"></CarrotTime24Select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <button @click="pop.doSearch" class="btn-default float-right mt-10">검색</button>
                <div class="clear"></div>

                <div class="mt-50 mb-20">
                    <table class="tbl-light-list">
                        <colgroup>
                            <col width="*">
                            <col width="100">
                            <col width="100">
                        </colgroup>
                        <thead>
                            <tr>
                                <th>강의실</th>
                                <th>최대 정원</th>
                                <th>예약하기</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in pop.list" :key="i">
                                <td>{{ irow.cname }} {{ irow.cnum }}</td>
                                <td>{{ irow.cnt_max }}</td>
                                <td>
                                    <span @click="pop.doSelect(i)" class="btn-view">예약하기</span>
                                </td>
                            </tr>
                            <tr v-if="pop.total==0">
                                <td colspan="3">예약 가능한 강의실이 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="pop.total" :list_per_page="pop.rows" v-model="pop.page" @change="pop.doSearch"></CarrotPaging>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js';
import CarrotTime24Select from '@/components/common/CarrotTime24Select.vue'
import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'

export default {
    name: 'ClassroomReservationAvailableSearchPopup',
    components: {
        CarrotTime24Select,
        CarrotDatePicker,
    },
    props: {
        modelValue: {
            type: [String, Object],
            default: "",
        }
    },
    emits: [ 'update:modelValue' ],
    setup(props, {emit}) {
        const toast = useToast();

        const comp = reactive({
            dayList: [ '월', '화', '수', '목', '금', '토', '일' ],
            changeDaytype : () => {
                if( pop.dtype == 'oneday' ){
                    pop.sdate = '';
                    pop.edate = '';
                } else {
                    pop.tdate = '';
                }
                pop.days = [];
            },

            changeDate: () => {
                pop.days = [];
                let td = new Date(pop.tdate);
                let day = td.getDay();
                if( day == 0 ) pop.days.push(comp.dayList[6])
                else pop.days.push(comp.dayList[day-1])
            }
        });

        const pop = reactive({
            is_mounted : false,

            page : 1,
            rows : 50,

            dtype : 'oneday',
            tdate : '',
            sdate : '',
            edate : '',
            days  : [],
            stime : '07:00',
            etime : '07:00',

            list : [], total : 0,
            
            doSearch : () => {
                if( pop.is_mounted == false ) return;
                let params = {
                    dtype : pop.dtype,
                    tdate : pop.tdate,
                    sdate : pop.sdate,
                    edate : pop.edate,
                    sday  : pop.days,
                    stime : pop.stime,
                    etime : pop.etime,
                };

                if( !params.tdate && (!params.sdate || !params.edate) ){
                    toast.error("기간을 선택하세요.");
                    return;
                }
                if( params.sday.length <= 0 ){
                    toast.error("요일을 선택하세요.");
                    return;
                }
                if( !params.stime || !params.etime ){
                    toast.error("시간을 선택하세요.");
                    return;
                }
                if( params.stime >= params.etime ){
                    toast.error("시간을 확인해 주세요.");
                    return;
                }

                axios.get('/rest/resourceManagement/getClassroomReservationAvailableList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        pop.list  = res.data.list;
                        pop.total = res.data.total;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })
            },

            doSelect : (i) => {
                let info = pop.list[i];
                emit('update:modelValue', info);
                emit('close');
            },

            onClose: () => {
                emit('close');
            }
        });

        onMounted(() => {
            // Mounted
            pop.is_mounted = true;
        });

        return {comp,pop};
    }
}
</script>
<style lang="scss" scoped>
.input-lock {
    position:absolute; top:0; left:0; width:100%; height:100%; z-index:2; background-color:rgba(0,0,0,0.001);
}
</style>